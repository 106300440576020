<template>
  <div class="flex align-items-end flex-wrap">
    <div class="mr-0 sm:mr-4 sm:w-auto w-full">
      <Button
        class="w-full align-items-center justify-content-center"
        type="button"
        label="Выберите действия с картами"
        @click="toggleMenu"
      />
      <Menu ref="menu" :model="items" :popup="true" />
    </div>
    <Dialog
      modal="true"
      draggable="false"
      header="Выберите группу карт"
      v-model:visible="displayGroup"
    >
      <GroupListBox :items="groupCardList" @selectGroup="bindCard" />
      <Listbox
        filter="true"
        style="width: 300px; height: 400px; overflow-y: scroll"
        v-model="selectItem"
        :options="items"
        @change="action($event)"
        optionLabel="name"
        emptyMessage="Нет груп компаний"
        emptyFilterMessage="Совпадения не найдены"
      />
    </Dialog>
    <div class="flex flex-wrap sm:w-auto w-full" style="">
      <MultiSelect
        v-show="!togglePlus"
        v-model="valueG"
        :options="groupCardList"
        :filter="true"
        optionLabel="name"
        :placeholder="placeholder"
        @change="change($event)"
        @input="change"
        :selectAll="false"
        :selectionLimit="10"
        emptyFilterMessage="Нет совпадений"
        display="chip"
        class="w-full"
        ref="select"
      >
        <template #footer>
          <div class="p-multiselect-footer bg-gray-300">
            <div
              class="flex align-items-center justify-content-end"
              style="padding: 0.75rem 1.25rem"
            >
              <Button
                @click="this.$refs.select.hide()"
                class="p-button-sm"
                label="Закрыть"
              />
            </div>
          </div>
        </template>
      </MultiSelect>
      <InputText
        type="search"
        v-show="togglePlus"
        @keypress.enter="action === 'add' ? addGroupCard() : editGroupCard()"
        v-model.trim="nameGroup"
        class="w-full"
        placeholder="Введите название будущей группы"
      />
    </div>
    <Button
      v-if="showFillter"
      class="ml-1 px-4 p-button-warning p-button-icon-only"
      icon="pi 
      pi-filter-slash
      "
      :disabled="!selectedItem.length"
      @click="unsetFilter"
      v-tooltip.left="'Сбросить филтр'"
    />
    <div class="toggle-btn">
      <ToggleButton
        class="mx-1 px-4 p-button-warning p-button-icon-only"
        onIcon="pi pi-bookmark"
        offIcon="pi pi-bookmark-fill"
        v-model="checked"
        v-tooltip.bottom="checked ? 'Карты без групп' : 'Все карты'"
      />
    </div>

    <div>
      <SplitButton
        :icon="{
          'pi pi-plus': !togglePlus,
          'pi pi-save': togglePlus,
          'pi pi-times': !nameGroup.length,
        }"
        :model="itemsBtn"
        :loading="loadbtn"
        @click="action === 'add' ? addGroupCard() : editGroupCard()"
        v-tooltip.left="tooltipLabel"
        class="p-button-sm p-button-warning"
      ></SplitButton>
    </div>
  </div>
</template>

<script>
import { getGroupCart } from "../api/group";
import store from "@/store";
export default {
  name: "SelectGrouping",
  props: {
    modelValue: Array,
    placeholder: String,
    items: {
      type: Array,
      requaired: true,
    },
  },
  data() {
    return {
      action: "add",
      groupCardList: [],
      valueG: this.modelValue,
      selectItem: this.groupCardList ? this.groupCardList : [],
      selectedItem: [],
      nameGroup: "",
      checked: true,
      array: null,
      togglePlus: false,
      loadbtn: false,
      items: [
        {
          label: "Группы карт",
          items: [
            {
              label: "Привязать к группе",
              icon: "pi pi-plus",
              command: () => {
                if (
                  this.groupTools.selectedCard !== null &&
                  this.groupCardList.length
                ) {
                  this.displayGroup = true;
                } else {
                  if (this.groupTools.selectedCard === null) {
                    this.$toast.add({
                      severity: "error",
                      summary: "Нет выбраных карт для привязки",

                      life: 3000,
                    });
                  }
                  if (!this.groupCardList.length) {
                    this.$toast.add({
                      severity: "error",
                      summary: "Нет ни одной группы",
                      detail:
                        "Создайте группу карт нажав на кнопку с '+', которая находится ниже, оранжевая, рядом еще кнопка со стрелочкой 'вниз'",
                      life: 15000,
                    });
                  }
                }
                // if (
                //   this.groupTools.selectedCard !== null &&
                //   this.selectedGroup !== null
                // ) {
                //   this.groupTools.selectedCard =
                //     this.groupTools.selectedCard.map((el) => el.number);
                //   // if (this.groupTools.selectedCard.length === 1) {
                //   //   this.groupTools.selectedCard =
                //   // }
                //   this.groupTools.idGroup = this.selectedGroup.id;
                //   this.groupTools.nameGroup = this.selectedGroup.name;
                //   bindingCardsforGroup(this);
                // } else {
                //   if (this.groupTools.selectedCard === null) {
                //     this.$toast.add({
                //       severity: "error",
                //       summary: "Нет выбраных карт для привязки",

                //       life: 3000,
                //     });
                //   }
                //   if (this.selectedGroup === null) {
                //     this.$toast.add({
                //       severity: "error",
                //       summary: "Выберите группу из списка 'Группы карт'",

                //       life: 3000,
                //     });
                //   }
                // }
              },
            },
            {
              label: "Отвязать от группы",
              icon: "pi pi-times",
              command: () => {
                if (this.groupTools.selectedCard !== null) {
                  this.groupTools.selectedCard =
                    this.groupTools.selectedCard.map((el) => el.number);
                  // if (this.groupTools.selectedCard.length === 1) {
                  //   this.groupTools.selectedCard =
                  // }
                  unbindingCardsforGroup(this);
                } else {
                  this.$toast.add({
                    severity: "error",
                    summary: "Нет выбраных карт для отвязки",

                    life: 3000,
                  });
                }
              },
            },
          ],
        },

        {
          label: "Статус",
          items: [
            {
              label: "Разблокировать",
              icon: "pi pi-plus",
              command: () => {
                this.$toast.add({
                  severity: "info",
                  summary: "Эх, как жаль, но пока не рабоатает...",
                  life: 1500,
                });
              },
            },
            {
              label: "Заблокировать",
              icon: "pi pi-times",
              command: () => {
                this.$toast.add({
                  severity: "info",
                  summary: "Эх, как жаль, но пока не рабоатает...",
                  life: 1500,
                });
              },
            },
          ],
        },
      ],
      displayGroup: false,
      company: store.state.company.curCompany,
      showFillter: true,
    };
  },
  methods: {
    change(event) {
      console.log(event.value.length > 0);
      if (event.value.length > 0) {
        this.valueG = [];
        this.valueG.push(event.value[event.value.length - 1]);
        this.$emit("update:modelValue", this.valueG);
      } else {
        this.$emit("update:modelValue", []);
      }
    },
    toggleMenu() {
      this.$refs.menu.toggle(event);
    },
    action(event) {
      console.log(event.value);
      // this.$emit('selectGroup', event.value)
    },
    addGroupCard() {
      if (this.togglePlus) {
        if (this.nameGroup.length) {
          this.$emit("group", { name: this.nameGroup, type: "add" });
          this.nameGroup = "";
          this.togglePlus = false;
          this.selectedItem = [];
        } else {
          this.togglePlus = false;
        }
        // this.$emit("activeGroup", this.selectedItem[0].name);
      } else {
        this.$emit("activeGroup", "Группы карт");

        this.togglePlus = true;
      }
    },
    delGroupCard() {},
    editGroupCard() {
      if (this.togglePlus) {
        if (this.nameGroup.length) {
          let findEl = this.items.find(
            (el) => el.id === this.selectedItem[0].id
          );
          console.log(findEl);
          let index = this.items.indexOf(findEl);
          this.$emit("group", {
            name: this.nameGroup,
            type: "edit",
            index,
            id: this.selectedItem[0]["id"],
          });

          this.togglePlus = false;
          this.selectedItem = [];
          this.nameGroup = "";
          this.action = "add";
        } else {
          this.togglePlus = false;
        }
      } else {
        this.togglePlus = true;
      }
    },
    toggleValue(event) {
      this.selectedItem = [];
      this.checked = true;
      if (event.value.length !== 0) {
        this.selectedItem.push(event.value[event.value.length - 1]);

        this.$emit("filter", this.selectedItem[0]);
      } else {
        this.$emit("filter", "");
      }
    },
    unsetFilter() {
      (this.selectedItem = []), this.$emit("filter", "");
    },
  },
  computed: {
    curCompany() {
      return store.state.company.curCompany;
    },
  },
  watch: {
    curCompany(value) {
      if (JSON.stringify(this.company) !== JSON.stringify(value)) {
        this.company = value;
      }
    },
    company(value) {
      this.loading = true;
      getGroupCart(this);
    },
  },
  mounted() {
    getGroupCart(this);
  },
};
</script>

<style>
</style>