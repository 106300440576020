<template>
  <div class="flex mt-2 w-full">
    <div class="w-full mb-2 sm:mb-0">
      <MultiSelect
        v-show="!togglePlus"
        v-model="selectedItem"
        :options="items"
        optionLabel="name"
        :filter="true"
        :showToggleAll="false"
        :placeholder="placeholder"
        :emptyMessage="emptyMessage"
        emptyFilterMessage="Нет совпадений"
        @change="toggleValue($event)"
        class="w-full"
        ref="select"
      >
        <template #footer>
          <div class="p-multiselect-footer bg-gray-300">
            <div
              class="flex align-items-center justify-content-end"
              style="padding: 0.75rem 1.25rem"
            >
              <Button
                @click="this.$refs.select.hide()"
                class="p-button-sm"
                label="Закрыть"
              />
            </div>
          </div>
        </template>
      </MultiSelect>
      <InputText
        type="search"
        v-show="togglePlus"
        @keypress.enter="action === 'add' ? addGroupCard() : editGroupCard()"
        v-model.trim="nameGroup"
        class="w-full"
        placeholder="Введите название будущей группы"
      />
    </div>
    <Button
      v-if="showFillter"
      class="ml-1 px-4 p-button-warning p-button-icon-only"
      icon="pi 
      pi-filter-slash
      "
      :disabled="!selectedItem.length"
      @click="unsetFilter"
      v-tooltip.left="'Сбросить филтр'"
    />
    <div class="toggle-btn">
      <ToggleButton
        class="mx-1 px-4 p-button-warning p-button-icon-only"
        onIcon="pi pi-bookmark"
        offIcon="pi pi-bookmark-fill"
        v-model="checked"
        v-tooltip.bottom="checked ? 'Карты без групп' : 'Все карты'"
      />
    </div>

    <div>
      <SplitButton
        :icon="{
          'pi pi-plus': !togglePlus,
          'pi pi-save': togglePlus,
          'pi pi-times': !nameGroup.length,
        }"
        :model="itemsBtn"
        :loading="loadbtn"
        @click="action === 'add' ? addGroupCard() : editGroupCard()"
        v-tooltip.left="tooltipLabel"
        class="p-button-sm p-button-warning"
      ></SplitButton>
    </div>
  </div>
</template>

<script>
import store from "@/store";
export default {
  name: "SelectGroupCard",
  props: {
    placeholder: {
      type: String,
      required: false,
    },
    items: {
      type: Array,
      required: true,
    },
    selectItem: {
      type: Array,
      required: false,
    },
    emptyMessage: {
      type: String,
      required: true,
    },
    loadingAddBtn: {
      type: Boolean,
      required: true,
    },
    selectedGroup: {
      type: String,
      required: false,
    },
    showFillter: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedItem: [],
      action: "add",
      nameGroup: "",
      checked: true,
      array: null,
      togglePlus: false,
      loadbtn: false,
      itemsBtn: [
        {
          label: "Редактировать группу",
          icon: "pi pi-pencil",
          command: () => {
            console.log();
            if (this.selectedItem.length) {
              this.togglePlus = true;
              this.nameGroup = this.selectedItem[0].name;
              this.action = "edit";
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Не выбрана группа карт",

                life: 3000,
              });
            }
            // this.nameGroup = this.selectedItem[0].name;
            // this.$toast.add({
            //   severity: "info",
            //   summary: "Эх, как жаль, но пока не рабоатает...",
            //   life: 3000,
            // });
          },
        },
        {
          label: "Удалить группу",
          icon: "pi pi-trash",
          command: () => {
            if (this.selectedItem.length) {
              let findEl = this.items.find(
                (el) => el.id === this.selectedItem[0].id
              );
              let index = this.items.indexOf(findEl);
              this.$emit("group", {
                index,
                id: this.selectedItem[0]["id"],
                type: "del",
              });
              this.selectedItem = "";
              this.$emit("activeGroup", "Группы карт");
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Не выбрана группа карт",

                life: 3000,
              });
            }
          },
        },
      ],
      defaultItems: [
        {
          name: "Все",
        },
        {
          name: "Без группы",
        },
      ],
    };
  },
  methods: {
    addGroupCard() {
      if (this.togglePlus) {
        if (this.nameGroup.length) {
          this.$emit("group", { name: this.nameGroup, type: "add" });
          this.nameGroup = "";
          this.togglePlus = false;
          this.selectedItem = [];
        } else {
          this.togglePlus = false;
        }
        // this.$emit("activeGroup", this.selectedItem[0].name);
      } else {
        this.$emit("activeGroup", "Группы карт");

        this.togglePlus = true;
      }
    },
    delGroupCard() {},
    editGroupCard() {
      if (this.togglePlus) {
        if (this.nameGroup.length) {
          let findEl = this.items.find(
            (el) => el.id === this.selectedItem[0].id
          );
          console.log(findEl);
          let index = this.items.indexOf(findEl);
          this.$emit("group", {
            name: this.nameGroup,
            type: "edit",
            index,
            id: this.selectedItem[0]["id"],
          });

          this.togglePlus = false;
          this.selectedItem = [];
          this.nameGroup = "";
          this.action = "add";
        } else {
          this.togglePlus = false;
        }
      } else {
        this.togglePlus = true;
      }
    },
    toggleValue(event) {
      this.selectedItem = [];
      this.checked = true;
      if (event.value.length !== 0) {
        this.selectedItem.push(event.value[event.value.length - 1]);

        this.$emit("filter", this.selectedItem[0]);
      } else {
        this.$emit("filter", "");
      }
    },
    unsetFilter() {
      (this.selectedItem = []), this.$emit("filter", "");
    },
  },
  created() {},
  computed: {
    curCompany() {
      return store.state.company.curCompany;
    },
    tooltipLabel() {
      // 'pi pi-plus': !togglePlus,
      //   'pi pi-save': togglePlus,
      //   'pi pi-times': !nameGroup.length,
      if (!this.togglePlus) {
        return "Добавить группу";
      } else if (this.togglePlus && !this.nameGroup.length) {
        return "Отменить";
      } else {
        return "Сохранить группу";
      }
    },
  },
  mounted() {
    this.loadbtn = this.loadingAddBtn;
    if (this.selectedGroup) {
      this.selectedItem = [this.selectedGroup];
    }
  },
  watch: {
    loadingAddBtn(value) {
      this.loadbtn = value;
    },
    curCompany() {
      this.selectedItem = "";
      this.checked = true;
    },
    checked(value) {
      if (value) {
        this.$emit("setFillGroup", "empty");
      } else {
        this.$emit("setFillGroup", "without_group");
        this.selectedItem = [];
      }
    },
    // loadbtn() {
    //   this.array = this.defaultItems.concat(this.items);
    // },
  },
};
</script>

<style lang="scss">
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: var(--base);
  span {
    color: #495057 !important;
  }
}
.p-tieredmenu {
  width: unset;
}
.toggle-btn {
  .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover,
  .p-togglebutton.p-button.p-highlight,
  .p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
  .p-togglebutton.p-button.p-highlight:hover .p-button-icon-right,
  .p-togglebutton.p-button.p-highlight .p-button-icon-left,
  .p-togglebutton.p-button.p-highlight .p-button-icon-right,
  .p-togglebutton.p-button.p-highlight:hover,
  .p-togglebutton.p-button.p-highlight .p-button-icon-left,
  .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover
    .p-button-icon-left,
  .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover
    .p-button-icon-right,
  .p-togglebutton.p-button.p-highlight .p-button-icon-left,
  .p-togglebutton.p-button.p-highlight .p-button-icon-right,
  .p-togglebutton.p-button .p-button-icon-left,
  .p-togglebutton.p-button .p-button-icon-right,
  .p-togglebutton.p-button.p-highlight .p-button-icon-left,
  .p-togglebutton.p-button.p-highlight .p-button-icon-right {
    background: var(--base) !important;
    border-color: var(--base) !important;
    color: var(--primaryTextColor) !important;
    span {
      &:hover {
        color: inherit !important;
      }
      color: inherit !important;
    }
  }
}
</style>