<template>
  <LayoutBase>
    <loading-progress></loading-progress>
    <MobileSpiner :loading="loading" />
    <div class="cards-modules">
      <TabView
        class="p-0"
        lazy
        v-model:activeIndex="active"
        @tab-change="tabChange($event)"
      >
        <TabPanel header="Управление картами" class="p-0">
          <CardsTable v-model:curCompanyList="currentCompany" />
        </TabPanel>
        <TabPanel :headerClass="'hidden'" header="Полный список карт">
          <CardFullTable v-model:curCompanyList="currentCompany" />
        </TabPanel>
      </TabView>
    </div>
  </LayoutBase>
</template>

<script>
import store from "@/store";

import LayoutBase from "../../components/layouts/LayoutBase";
import CardFullTable from "./components/CardFullTable.vue";
import CardsTable from "./components/CardsTable";

export default {
  components: {
    LayoutBase,
    CardsTable,
    CardFullTable,
  },
  name: "Cards",
  data() {
    return {
      loading: true,
      labelGroupBtn: "Группы карт",
      groupTools: {
        nameGroup: "",
        loadingAddBtn: null,
        indexElement: null,
        idGroup: null,
        selectedCard: null,
        without_group: null,
        with_group: null,
        filterName: null,
      },
      active: sessionStorage.getItem("cards-tab")
        ? Number(sessionStorage.getItem("cards-tab"))
        : 0,
      currentCompany: [store.state.company.curCompany.id],
    };
  },
  methods: {
    tabChange(e) {
      console.log(e.index);
      sessionStorage.setItem("cards-tab", e.index);
      history.replaceState({ back: "" }, null, window.location.href);
    },
  },
  created() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
  mounted() {
    // // console.log();
    // // console.log(
    // //   window.sessionStorage.getItem("card-table") !== undefined
    // //     ? JSON.parse(window.sessionStorage.getItem("card-table")).filters
    // //         .company.value
    // //     : null
    // // );
    // let arr = JSON.parse(window.sessionStorage.getItem("card-table"))?.filters
    //   ?.company?.value;
    // if (
    //   !arr.every((value, index) => value === this.currentCompany[index]) &&
    //   history.state.back.split("/")[1] === "card"
    // ) {
    //   this.currentCompany = arr;
    // }
  },
};
</script>

<style lang="scss">
.p-tabview-panels {
  padding: 0 0 .5rem !important;
  background-color: var(--gray-200) !important;
}
.p-tabview {
  .p-tabview-nav {
    li {
      &:last-child {
        display: none !important;
      }
    }
  }
}
.cards-modules .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding-top: 0.1rem;
  padding-bottom: 0.5rem;
  height: 25px !important;
}
</style>