<template>
  <div style="width: 16px" class="mr-1">
    <Button
      type="button"
      style="width: 16px; overflow: visible"
      icon="pi pi-filter"
      :class="{
        'fil-btn': selectGroup != null || toggler,
      }"
      class="p-button-sm p-button-text p-0"
      @click="toggle"
    />
    <OverlayPanel
      class="select-fil"
      ref="filters"
      :showCloseIcon="true"
      ariaCloseLabel=""
    >
      <div>
        <Listbox
          filter
          v-model="selected"
          :options="list"
          optionLabel="name"
          optionValue="id"
          class="w-full pt-0"
          listStyle="height:250px"
          emptyMessage="Нет данных"
          emptyFilterMessage="Нет данных"
          @change="this.$emit('update:togglerGroup', null), (toggler = null)"
        />
        <div class="flex align-items-center pl-3 mt-4 mx-auto">
          <Checkbox
            v-model="toggler"
            inputId="addNow1"
            binary
            @input="
              this.$emit('update:togglerGroup', !toggler), (selected = null)
            "
            name="addNow"
          />
          <label for="addNow1" class="ml-2"> Без группы </label>
        </div>
        <Button
          class="mx-auto mt-4 p-1 block w-10 p-button-text underline"
          @click="
            (selected = null),
              this.$emit('update:togglerGroup', null),
              (toggler = null)
          "
          label="Сбросить"
        />
        <Button
          class="mt-4 mx-auto block w-10"
          @click="showValue"
          label="Пременить"
        />
      </div>
    </OverlayPanel>
  </div>
</template>
      
      <script>
import { getGroupCart } from "../api/group";
export default {
  name: "GroupFilters",
  props: {
    selectGroup: {
      type: Number,
      requaried: true,
      default: null,
    },
    togglerGroup: {
      type: Boolean,
      requaried: true,
      default: null,
    },
  },
  data() {
    return {
      list: [],
      selected: this.selectGroup,
      toggler: this.togglerGroup,
    };
  },
  methods: {
    isSearcDisabled() {
      if (this.selected) {
        this.isSearcDisables = false;
      } else {
        this.isSearcDisables = true;
        this.$emit("update:selectGroup", null);
      }
    },
    showValue() {
      this.$emit("update:selectGroup", this.selected);
      this.$emit("updateter");
      this.toggle();
    },
    toggle(event) {
      this.$refs.filters.toggle(event);
    },
  },
  created() {
    this.selected
      ? (this.isSearcDisables = false)
      : (this.isSearcDisables = true);
  },
  computed: {
    //   modelValue: {
    //   get () { return this.modelValue },
    //   set (value) { this.$emit('update:modelValue', value) },
    // }
  },
  watch: {
    selectGroup(val) {
      if (val == null) [(this.selected = null)];
    },
  },
  mounted() {
    getGroupCart(this);
  },
};
</script>
      
      <style lang="scss">
.select-fil {
  .p-overlaypanel-content {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }

  .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
    color: #495057 !important;
  }
}
.pi.pi-filter.p-button-icon {
  color: #495057 !important;
}
</style>