<template>
  <Button
    v-bind:badge="!cardNumber.length ? '' : ' '"
    badgeClass="p-badge-warning"
    label="Управление группами"
    class="p-button-sm px-4 mr-2 flex justify-content-center"
    @click="getGruopsList"
    :disabled="disabled"
    style="height: 2rem"
  ></Button>

  <Dialog
    v-model:visible="visible"
    :modal="true"
    :draggable="false"
    header="Список держателей"
    contentClass="h-screen"
    :style="{ width: '50vw' }"
    style="min-height: 50vh"
    :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
  >
    <template #header>
      <div>
        <ConfirmPopup ref="confGroup" class="w-20rem" group="demo">
          <template #message="slotProps">
            <div class="flex p-4 flex-column w-full">
              <div class="flex mb-4 align-items-center">
                <i :class="slotProps.message.icon" class="text-xl"></i>
                <p class="pl-2">{{ slotProps.message.message }}</p>
              </div>
              <InputText
                ref="inputNameHolder"
                autofocus
                class="p-2"
                v-model.trim="newGroupName"
                @keydown.enter="enterAddGroup"
                @keydown.esc="closeAddGroup"
              />

              <div
                v-if="cardNumber.length"
                class="flex align-items-center mt-4"
              >
                <Checkbox
                  v-model.trim="addNow"
                  inputId="addNow1"
                  binary
                  name="addNow"
                />
                <label for="addNow1" class="ml-2"> Привязать к карте </label>
              </div>
            </div>
          </template>
        </ConfirmPopup>
        <div class="mb-4">
          <Button
            class="mb-2 mr-0 px-4 p-button-sm sm:border-noround border-round-left"
            style="border-right: 1px solid white"
            icon="pi"
            label="Создать группу"
            :loading="loading"
            @click="addGroup($event)"
          />
          <Button
            class="p-button-sm px-4 mb-2 mr-0 justify-content-center sm:border-noround border-round-right"
            icon="pi"
            :disabled="!cardNumber.length"
            label="Отвязать группу"
            @click="unbindGroup"
          />
        </div>
      </div>
    </template>
    <DataTable
      :value="list"
      :loading="loading"
      class="p-datatable-sm"
      dataKey="id"
      editMode="row"
      @row-edit-save="onRowEditSave"
      @rowSelect="onRowSelect"
      v-model:editingRows="editingRows"
      v-model:selection="selectedGroup"
      v-model:filters="filters"
      ref="groupTables"
    >
      <template #header>
        <div v-if="!cardNumber.length" class="limters-message mb-2">
          <div class="limiters-msg">
            <b>Внимание!</b> <br />
            Чтобы привязать/отвязать карты к группе, нужно закрыть это окно и
            выбрать карты из списка на странице "Карты"
          </div>
        </div>
        <!-- <div v-else>
          <b>Выбранные карты</b> <br />
          {{ cardNumber }}
        </div> -->
        <div class="flex justify-content-start">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              :disabled="!list"
              v-model="filters['name'].value"
              type="search"
              placeholder="Название группы"
            />
          </span>
        </div>
      </template>
      <template #empty>
        <div
          class="flex justify-content-center flex-column align-items-center my-6"
        >
          <i class="pi pi-inbox text-6xl mb-4 text-gray-700"> </i>
          <span class="text-gray-500">Нет данных</span>
        </div>
      </template>
      <Column
        v-if="cardNumber.length"
        selectionMode="single"
        headerStyle="width: 3rem"
      ></Column>

      <Column field="name" header="Название" style="max-width: 300px">
        <template #editor="{ data, field }">
          <InputText
            class="p-2 w-full"
            v-model="data[field]"
            @keydown.enter="enter(data[field])"
            @keydown.esc="cancel(data[field])"
          /> </template
      ></Column>
      <Column
        :rowEditor="true"
        style="width: 8rem"
        bodyStyle="text-align:center"
      ></Column>
      <Column bodyStyle="text-align:center">
        <template #body="slotProps">
          <ConfirmPopup />
          <Button
            :loading="false"
            icon="pi pi-trash"
            class="p-button-danger p-button-text p-button-rounded"
            severity="danger"
            text
            rounded
            aria-label="Удаление"
            @click="delGroup($event, slotProps.data.id)"
          /> </template
      ></Column>
    </DataTable>
  </Dialog>
</template>

<script>
import {
  getGroupCart,
  bindingCardsforGroup,
  unbindingCardsforGroup,
  addGroupCart,
  delGroupCart,
  editNameGroupCart,
} from "../api/group";
import { FilterMatchMode } from "primevue/api";
export default {
  name: "CardGroup",
  props: {
    company: {
      type: Number,
      required: true,
    },
    cardNumber: {
      type: Array,
      requaried: true,
    },
    disabled: {
      type: Boolean,
      requaried: true,
    },
  },
  data() {
    return {
      visible: false,
      loading: !this.visible,
      list: [],
      editingRows: [],
      selectedGroup: this.selectGroup,
      addNow: false,
      newGroupName: null,
      filters: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  methods: {
    getGruopsList() {
      this.visible = true;
      getGroupCart(this);
    },
    updateHistoryCard() {
      this.$emit("updateHistory", {});
    },
    delGroup(event, id) {
      console.log(event, id);
      this.$confirm.require({
        target: event.currentTarget,
        message: "Вы действительно хотите удалить группу?",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          this.loading = true;
          delGroupCart(this, id);
        },
      });
    },
    addGroup(event) {
      setTimeout(() => {
        this.$refs.inputNameHolder.$el.focus();
      }, 50);
      this.$confirm.require({
        target: event.currentTarget,
        group: "demo",
        message: "Введите название",
        icon: "pi pi-user-plus",
        acceptIcon: "pi pi-check",
        rejectIcon: "pi pi-times",
        accept: () => {
          this.loading = true;
          addGroupCart(this);
        },
        reject: () => {
          console.log(this.$refs.inputNameHolder);
          this.newGroupName = null;
        },
      });
    },
    enter(event) {
      this.$refs.groupTables.onRowEditInit(event);
    },
    enterAddGroup() {
      this.$refs.confGroup.accept();
    },
    closeAddGroup() {
      this.$refs.confGroup.reject();
    },
    cancel(event) {
      this.$refs.groupTables.onRowEditCancel(event);
    },
    onRowEditSave(event) {
      let { newData, index, data } = event;
      if (JSON.stringify(newData) != JSON.stringify(data)) {
        this.loading = true;
        editNameGroupCart(this, newData, data, index);
      } else {
        return;
      }
    },
    onRowSelect(event) {
      this.loading = true;
      bindingCardsforGroup(this, event.data.id);
    },
    updateGroupInCard(val) {
      this.$emit("update:selectGroup", val);
    },
    unbindGroup() {
      unbindingCardsforGroup(this);
    },
  },
};
</script>

<style>
.p-confirm-popup.p-component,
.p-confirm-popup.p-component.p-confirm-popup-flipped {
  z-index: 10005 !important;
}

.p-dialog-mask.p-component-overlay.p-component-overlay-enter {
  z-index: 10004 !important;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  outline: none !important;
  background-color: rgba(90, 146, 249, 0.368627451) !important;
  color: #495057;
}
.p-dialog.p-dialog-maximized {
  max-height: 100vh;
}
.limiters-msg {
  background: #fff2e2;
  border: solid rgba(204, 137, 37, 0.2509803922);
  border-width: 1px;
  color: #cc8925;
  padding: 1rem;
}
</style>