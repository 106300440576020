<template>
  <div
    class="card-wrapper h-screen"
    v-if="curCompany !== null"
    :class="{ ' overlock': loading, 'h-auto': !loading }"
  >
    <div
      class="table-header-container flex bg-gray-200 p-1 justify-content-between"
    >
      <div class="p-inputgroup sm:max-w-20rem w-full sm:w-9">
        <InputText
          class="w-full"
          type="search"
          v-model.trim="search"
          @keypress.enter="searchCardOrHolder"
          placeholder="Номер карты или держателя"
          style="width: 2rem; height: 2rem"
        />
        <Button
          :disabled="!search.length"
          icon="pi pi-search"
          @click="searchCardOrHolder"
          severity="warning"
          style="width: 2rem; height: 2rem"
        />
      </div>
      <div class="align-items-stretch hidden md:flex">
        <card-group
          :disabled="
            filters.company.value != null && filters.company.value.length > 1
          "
          v-if="$store.state.curUser && $store.state.curUser.edit_card"
          :company="
            filters.company.value != null ? filters.company.value[0] : null
          "
          :cardNumber="selectedCard.map((el) => el.number)"
          @updateHistory="changeValTogglerGroup"
        />
        <div class="flex align-items-stretch">
          <Button
            v-tooltip.left="'Сбросить все фильтры'"
            @click="clearFilters"
            icon="pi pi-filter-slash"
            style="width: 2rem; height: 2rem"
          />
          <Button
            class="ml-2"
            style="width: 2rem; height: 2rem"
            v-tooltip.left="'Обновить данные'"
            @click="loadPage"
            icon="pi pi-refresh"
          />
        </div>
      </div>
    </div>
    <DataTable
      stateStorage="session"
      stateKey="card-table"
      resizableColumns
      columnResizeMode="fit"
      class="p-datatable-sm table-cards h-full"
      showGridlines
      :value="cards"
      lazy
      :rows="rows"
      :totalRecords="totalCount"
      :loading="loading"
      dataKey="number"
      v-model:selection="selectedCard"
      v-model:expandedRows="expandedRows"
      v-model:filters="filters"
      @filter="applayFilters($event)"
      @rowCollapse="onRowCollapse"
      @rowExpand="onRowExpand"
      filterDisplay="menu"
      ref="cardT"
    >
      <template #empty>
        <div
          class="flex justify-content-center flex-column align-items-center my-6"
        >
          <i class="pi pi-inbox text-6xl mb-4 text-gray-700"> </i>
          <span class="text-gray-500">Нет данных</span>
        </div>
      </template>
      <Column
        headerStyle="max-width: 4rem; width: 4rem;  min-width: 4rem"
        bodyStyle="max-width: 4rem; width: 4rem;  min-width: 4rem"
        field="row_number"
      />
      <Column
        v-if="$store.state.curUser && $store.state.curUser.edit_card"
        selectionMode="multiple"
        headerStyle="max-width: 3rem; width: 3rem;  min-width: 3rem"
        bodyStyle="max-width: 3rem; width: 3rem; min-width: 3rem"
      ></Column>
      <Column
        :expander="true"
        headerStyle="max-width: 3rem; width: 3rem;  min-width: 3rem; text-align: center"
        bodyStyle="max-width: 3rem; width: 3rem;  min-width: 3rem"
      >
        <template #header>
          <ToggleButton
            style="
              color: var(--base2) !important;
              border: 0 !important;
              background: transparent;
            "
            class="p-button-sm flex justify-content-center"
            v-model="showHide"
            v-tooltip="tooltipLabelOpenShow"
            onIcon="pi pi-plus"
            offIcon="pi pi-minus"
          />
        </template>
      </Column>
      <Column
        field="status"
        header="Статус"
        :showFilterMatchModes="false"
        :showClearButton="false"
        :showApplyButton="false"
        :filterMenuClass="'p-0-super'"
      >
        <template #body="slotProps">
          <div v-if="$store.state.curUser && $store.state.curUser.edit_card">
            <div v-if="slotProps.data.card_type.block">
              <CardsStatusSwitcher
                :disabled="
                  ($store.state.curUser.edit_card &&
                    slotProps.data.status.pk == 2 &&
                    slotProps.data.card_type.block) ||
                  slotProps.data.disabled
                "
                :valueStatus="slotProps.data.checkStatus"
                :tooltip="
                  getTooltipSwitcher(
                    slotProps.data.checkMess,
                    slotProps.data.status.pk,
                    $store.state.curUser.edit_card,
                    slotProps.data.card_type.block
                  )
                "
                :dsc="slotProps.data.status.name"
                :cardNumber="slotProps.data.number"
              />
            </div>
            <div v-else>
              {{ slotProps.data.status.name }}
            </div>
          </div>
          <div v-else>
            {{ slotProps.data.status.name }}
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <MultiSelect
            display="chip"
            v-model="filterModel.value"
            :options="statusItem"
            style="width: 400px; max-width: 400px"
            filter
            :showToggleAll="false"
            optionLabel="name"
            optionValue="value"
            placeholder="Выберите статус"
            class="p-column-filter"
            @change="selectingStatus($event)"
            ref="selectingStatus"
          >
            <template #footer>
              <div class="flex justify-content-between gap-4 p-4 bg-gray-400">
                <ToggleButton
                  v-model="checkedAllStatus"
                  onLabel="Сбросить все"
                  offLabel="Выбрать все"
                  @click="this.$refs.selectingStatus.onToggleAll()"
                />

                <Button label="Применить" @click="filterCallback()" />
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column
        field="number"
        header="Номер карты"
        :showFilterMatchModes="false"
        :showClearButton="false"
        headerStyle="text-align: left; width: 240px"
        bodyStyle=" width: 240px"
      >
        <template #body="slotProps">
          <router-link
            style="color: var(--base2)"
            :to="{
              name: 'CardPage',
              params: {
                id: slotProps.data.number,
              },
            }"
          >
            <div class="flex align-items-center cursor-pointer">
              <img
                style="margin-right: 1rem; width: 3rem"
                :src="
                  getCardDesign(
                    slotProps.data.card_type.code,
                    slotProps.data.handler_code,
                    'cardIcon'
                  )
                "
              />
              <span
                style="letter-spacing: 0.051rem"
                class="underline font-bold"
              >
                {{ slotProps.data.number }}</span
              >
            </div>
          </router-link>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="search"
            :useGrouping="false"
            v-model.trim="filterModel.value"
            class="p-column-filter"
            placeholder="Введите номер карты"
            @keypress.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="company"
        header="Компания"
        :showFilterMatchModes="false"
        :showApplyButton="false"
        :showClearButton="false"
        :filterMenuClass="'p-0-super'"
        headerClass="w-60px"
        ref="comanyColumn"
      >
        <template #body="slotProps">
          {{ getCompanyName(slotProps.data.company)["0"]["name"] }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <MultiSelect
            display="chip"
            v-model="filterModel.value"
            :options="companyList"
            style="width: 40vw; max-width: 50vw"
            filter
            :showToggleAll="false"
            optionLabel="name"
            optionValue="id"
            placeholder="Выберите компанию"
            class="p-column-filter"
            ref="selecting"
            @change="selectingCompany($event)"
            emptyFilterMessage="Нет данных"
            emptyMessage="Нет данных"
          >
            <template #footer>
              <div class="flex justify-content-between gap-4 p-4 bg-gray-400">
                <ToggleButton
                  v-model="checkedAllCompany"
                  onLabel="Сбросить все"
                  offLabel="Выбрать все"
                  @click="this.$refs.selecting.onToggleAll()"
                />

                <Button
                  label="Применить"
                  @click="filterCallback(), hide($event)"
                />
              </div>
            </template>
          </MultiSelect>
          <!-- <Listbox v-model="filterModel.value" :options="companyList" filter optionLabel="name" listStyle="max-height:250px"  optionValue="id" class="w-full" /> -->
        </template>
      </Column>
      <Column field="company" header="Договора, №">
        <template #body="slotProps">
          {{ getCompanyName(slotProps.data.company)["0"].contract_num }}
        </template>
      </Column>
      <div v-if="$store.state.curUser">
        <div v-if="$store.state.curUser.role == 1">
          <Column
            field="handler_name"
            header="Обработчик"
            :showFilterMatchModes="false"
            :showApplyButton="false"
            :showClearButton="false"
            :filterMenuClass="'p-0-super'"
          >
            <template #filter="{ filterModel, filterCallback }">
              <MultiSelect
                display="chip"
                v-model="filterModel.value"
                :options="handlersList"
                style="width: 400px; max-width: 400px"
                filter
                :showToggleAll="false"
                optionLabel="full_name"
                optionValue="code"
                dataKey="id"
                placeholder="Выберите обоработчика"
                class="p-column-filter"
                emptyFilterMessage="Нет данных"
                emptyMessage="Нет данных"
                ref="selectingHandlers"
                @change="selectingHandlers($event)"
              >
                <template #footer>
                  <div
                    class="flex justify-content-between gap-4 p-4 bg-gray-400"
                  >
                    <ToggleButton
                      v-model="checkedAllHandlers"
                      onLabel="Сбросить все"
                      offLabel="Выбрать все"
                      @click="this.$refs.selectingHandlers.onToggleAll()"
                    />
                    <Button label="Применить" @click="filterCallback()" />
                  </div>
                </template>
              </MultiSelect>
            </template>
          </Column>
        </div>
      </div>
      <Column
        ref="colCompa"
        field="group"
        header="Группа"
        :showFilterMatchModes="false"
        :showApplyButton="false"
        :showClearButton="false"
        :filterMenuClass="'p-0-super'"
        :filterHeaderClass="'p-column-filter-menu-button-active'"
      >
        <!-- <template #header>
          <group-filters
            v-model:togglerGroup="togglerGroup"
            v-model:selectGroup="groupCardId"
            @updateter="updateGroupCardId"
          />
        </template> -->
        <template #body="slotProps">
          {{
            slotProps.data.group !== null
              ? slotProps.data.group.name
              : "Без группы"
          }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <MultiSelect
            :selectionLimit="1"
            display="chip"
            v-model="filterModel.value"
            :options="list"
            style="width: 400px; max-width: 400px"
            filter
            :showToggleAll="false"
            optionLabel="name"
            optionValue="id"
            placeholder="Выберите группу"
            class="p-column-filter"
            emptyFilterMessage="Нет данных"
            emptyMessage="Нет данных"
            @change="selectingGroup($event)"
            ref="selectingGroup"
          >
            <template #footer>
              <div class="flex justify-content-between gap-4 p-4 bg-gray-400">
                <div class="flex align-items-center">
                  <Checkbox
                    v-model="checkedWithoutGroup"
                    @input="clearSelectingGroup($event)"
                    inputId="addNow1"
                    binary
                    name="addNow"
                  />
                  <label for="addNow1" class="ml-2"> Без группы </label>
                </div>
                <Button label="Применить" @click="filterCallback()" />
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column
        field="holder"
        header="Держатель"
        :showFilterMatchModes="false"
        :showClearButton="false"
      >
        <template #body="slotProps">
          <card-holders
            v-if="$store.state.curUser && $store.state.curUser.edit_card"
            :card-number="slotProps.data.number"
            :company="slotProps.data.company"
            v-model:selectHolder="slotProps.data.holder"
            @updatedCardList="loadPage"
          />
          <div v-else>
            {{
              slotProps.data.holder !== null
                ? slotProps.data.holder.name
                : "Без держателя"
            }}
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="search"
            v-model.trim="filterModel.value"
            class="p-column-filter"
            placeholder="Введите псевдоним"
            @keypress.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column field="last_time_transaction" header="Дата последней операции">
        <template #body="slotProps">
          <div style="word-spacing: 0.5rem">
            {{ slotProps.data?.last_time_transaction }}
          </div>
        </template>
      </Column>
      <div v-if="$store.state.curUser">
        <div v-if="$store.state.curUser.role == 1">
          <Column
            field="card_type"
            header="Тип карты"
            headerStyle=" width: 6rem;  min-width: 6rem;"
            bodyStyle="   width: 6rem;  min-width: 6rem"
          >
            <template #body="{ data }">
              {{ data?.card_type?.name }}
            </template>
          </Column>
        </div>
      </div>
      <template #expansion="slotProps">
        <div class="flex pb-4 flex-wrap md:flex-nowrap w-full">
          <div
            v-if="slotProps.data.card_type.show_limiter"
            class="p-3 w-full surface-border border-right-1"
          >
            <h4>Ограничители</h4>
            <br />
            <DataTable class="p-datatable-sm" :value="slotProps.data.limiter">
              <template #empty>
                <div
                  class="flex justify-content-center flex-column align-items-center my-6"
                >
                  <i class="pi pi-inbox text-6xl mb-4 text-gray-700"> </i>
                  <span class="text-gray-500">Нет данных</span>
                </div>
              </template>
              <Column field="service" header="Услуги" bodyClass="py-2">
                <template #body="slotProps">
                  <div class="flex">
                    <span
                      v-if="slotProps.data.service !== null"
                      class="text-gray-900"
                    >
                      {{
                        slotProps.data.service
                          ? slotProps.data.service.name
                          : ""
                      }}</span
                    >
                    <span v-else class="text-gray-900">
                      {{
                        slotProps.data.cat ? slotProps.data.cat.name : ""
                      }}</span
                    >
                    <span
                      class="ml-2"
                      :class="{
                        'text-green-700': slotProps.data.type_limiter.pk === 1,
                        'text-red-700': slotProps.data.type_limiter.pk === 2,
                      }"
                      >{{ slotProps.data.type_limiter.name }}
                    </span>
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
          <div
            class="p-3 w-full surface-border"
            :class="{ ' border-left-1': slotProps.data.limiter.length }"
          >
            <h4>Лимиты</h4>
            <br />
            <DataTable
              :value="
                slotProps.data.card_type.pk !== 3
                  ? slotProps.data.limits
                  : slotProps.data.limits.filter((el) => el.limit > 0)
              "
              dataKey="id_from"
            >
              <template #empty>
                <div
                  class="flex justify-content-center flex-column align-items-center my-6"
                >
                  <i class="pi pi-inbox text-6xl mb-4 text-gray-700"> </i>
                  <span class="text-gray-500">Нет данных</span>
                </div>
              </template>
              <Column
                field="service"
                header="Услуги"
                class="max-w-full md:max-w-10rem"
              >
                <template #body="slotProps">
                  <div class="inline">
                    <span
                      v-if="slotProps.data.cat !== null"
                      style="background: transparent"
                    >
                      <span class="text-gray-900 ml-2">{{
                        slotProps.data.cat !== null
                          ? slotProps.data.cat.name
                          : ""
                      }}</span>
                    </span>
                    <span v-if="slotProps.data.service.length !== 0">
                      <span
                        v-for="(s, i) in slotProps.data.service"
                        :key="i"
                        class="ml-2"
                        style="background: transparent"
                      >
                        <span class="text-gray-900">{{ s.name }}</span>
                      </span>
                    </span>
                  </div>
                </template>
              </Column>
              <Column field="limit" header="Количество" style="width: 20%">
              </Column>
              <Column
                v-if="
                  (slotProps.data.card_type.code == 'RN' ||
                    slotProps.data.card_type.code == 'PPLUS') &&
                  slotProps.data.limits.some((el) => el.cur_limit != null)
                "
                field="cur_limit"
                header="Остаток"
              >
              </Column>
              <Column field="period_limit.name" header="Период"></Column>
              <Column field="unit.name" header="Единица"></Column>
            </DataTable>
          </div>
        </div>
      </template>
    </DataTable>
    <!-- <Paginator
      @page="onPage($event)"
      :totalRecords="totalCount"
      :page="page"
      :rows="rows"
      class="card-paginator p-2 p-paginator-sm"
      :rowsPerPageOptions="[10, 30, 50, 100, 500]"
      template="FirstPageLink PrevPageLink  JumpToPageInput CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown "
      currentPageReportTemplate="из {totalPages}"
    /> -->
    <the-paginator
      class="bg-gray-200 fixed left-0 right-0 cards-fulltable-pgn pt-2"
      @page="onPage"
      :totalRecords="Boolean(totalCount) ? totalCount : 1"
      :rows="rows"
      :page="page"
      :globalDisabel="loading"
      :rowsPerPageOptions="[30, 50, 100, 300, 500, 1000]"
    />
  </div>
</template>

<script>
import CardSelectGroup from "./CardSelectGroup";
import SelectGroupCard from "../../../components/Card/SelectGroupCard";
import store from "@/store";
import config from "@/configs";
import { getCards } from "../api/cards";
import CardsStatusSwitcher from "./CardsStatusSwitcher.vue";
import CardHolders from "../components/CardHolders.vue";
import GroupFilters from "./GroupFilters.vue";
import CardGroup from "./CardGroup.vue";

export default {
  name: "CardsTable",
  components: {
    CardSelectGroup,
    SelectGroupCard,
    CardsStatusSwitcher,
    CardHolders,
    GroupFilters,
    CardGroup,
  },
  props: {
    curCompanyList: {
      type: Array,
      requaried: true,
    },
  },
  data() {
    return {
      loading: false,
      config: config,
      cards: [],
      rows: 100,
      totalCount: null,
      page: 1,
      togglerGroup: null,
      selectedCard: [],
      groupCardId: null,
      expandedRows: [],
      showHide: true,
      tooltipLabelOpenShow: "Раскрыть все карты",
      statusItem: [
        {
          value: 1,
          name: "Активна",
        },
        {
          value: 2,
          name: "Заблокирована",
        },
        {
          value: 3,
          name: "Заблокирована вручную",
        },
        {
          value: 4,
          name: "Закрыта",
        },
      ],
      checkedAllStatus: false,
      checkedAllCompany: false,
      checkedAllHandlers: false,
      checkedWithoutGroup: false,
      filters: {
        number: { value: null },
        company_contract_num: {
          value: null,
        },
        holder: {
          value: null,
        },
        company: {
          value: this.curCompanyList,
        },
        status: {
          value: null,
        },
        group: {
          value: null,
        },
        handler_name: {
          value: null,
        },
      },
      handlersList: null,
      list: null,
      search: "",
    };
  },
  methods: {
    hide(e) {
      this.$refs["selecting"].$parent.$parent.hide();
    },
    searchCardOrHolder() {
      if (this.search.length) {
        this.loadPage();
      }
    },
    clearFilters() {
      Object.keys(this.filters).forEach((el) => {
        this.filters[el].value = null;
      });
      this.checkedWithoutGroup = false;
      this.loadPage();
    },
    selectingGroup(e) {
      if (e.value.length > 0) {
        this.checkedWithoutGroup = false;
      }
    },
    clearSelectingGroup(e) {
      if (e && this.$refs.selectingGroup.modelValue != null) {
        // this.$refs.selectingGroup.modelValue[0] = this.list[0]["id"];
        this.filters.group.value = 0;
        this.$refs.selectingGroup.$emit("update:modelValue");
      }
      if (e) {
        this.filters.group.value = 0;
      } else {
        this.filters.group.value = null;
      }
    },
    updateCompanyInParent() {
      this.$emit("update:curCompanyList", this.filters.company.value);
    },
    selectingCompany(e) {
      this.$emit("update:curCompanyList", e.value);
      if (e.value.length == this.companyList.length) {
        this.checkedAllCompany = true;
      } else {
        this.checkedAllCompany = false;
      }
    },
    selectingHandlers(e) {
      if (e.value.length == this.handlersList.length) {
        this.checkedAllHandlers = true;
      } else {
        this.checkedAllHandlers = false;
      }
    },
    selectingStatus(e) {
      if (e.value.length == this.statusItem.length) {
        this.checkedAllStatus = true;
      } else {
        this.checkedAllStatus = false;
      }
    },
    applayFilters(event) {
      this.loadPage();
    },
    expandAll() {
      this.expandedRows = this.cards.filter((p) => p.number);
    },
    collapseAll() {
      this.expandedRows = null;
    },
    // onPage(event) {
    //   if (this.page != event.page + 1 || this.rows != event.rows) {
    //     this.page = event.page + 1;
    //     this.rows = event.rows;
    //     this.loadPage();
    //   }
    // },
    onPage(data) {
      this.page = data.page;
      this.rows = data.rows;
      this.loadPage();
    },
    getTooltipSwitcher(msg, status, edit, block) {
      if (status === 2) {
        return "Для разблокировки карты обратитесь к Вашему менеджеру";
      } else if (!edit && !block) {
        return "У данного типа карты нельзя изменить статус вручную";
      } else {
        return msg;
      }
    },
    getCardDesign(code, subCode, setting) {
      let param = this.config.CARD_DISIGN["DEFAULT"][setting];
      if (code) {
        let disignSettings = this.config.CARD_DISIGN;
        let subDisignSettings = this.config.CARD_DISIGN.PPLUS;
        let arrayKey = [];
        let subArrayKey = [];

        for (let value of Object.keys(disignSettings)) {
          arrayKey.push(value);
        }
        for (let value of Object.keys(subDisignSettings)) {
          subArrayKey.push(value);
        }
        arrayKey.forEach((element) => {
          if (element === code) {
            if (code === "PPLUS") {
              subArrayKey.forEach((el) => {
                if (el == subCode) {
                  param = this.config.CARD_DISIGN[code][subCode][setting];
                }
              });
            } else {
              param = this.config.CARD_DISIGN[element][setting];
            }
          }
        });
      }
      return param;
    },
    getCompanyName(id) {
      return [...store.state.company.companyList].filter((el) => el.id === id);
    },
    loadPage() {
      this.loading = true;
      setTimeout(() => {
        getCards(this);
      }, Math.random() * 1000 + 250);
    },
    changeValTogglerGroup() {
      this.selectedCard = [];
      this.groupCardId = null;
      this.loadPage();
    },
    updateGroupCardId() {
      // this.togglerGroup = null;
      this.loadPage();
    },
  },
  created() {},
  beforeCreate() {
    if (history?.state?.back?.split("/")[1] != "card") {
      sessionStorage.removeItem("card-table");
    }
  },
  computed: {
    curCompany() {
      return store.state.company.curCompany;
    },
    companyList() {
      return store.state.company.companyList.map(function (el) {
        return {
          id: el.id,
          name: el.full_name,
        };
      });
    },
  },
  watch: {
    curCompany(value) {
      if (this.company !== value?.id) {
        this.company = value.id;
      }
    },
    company(value) {
      this.loadPage();
    },
    showHide(value) {
      if (!value) {
        this.expandedRows = this.cards.filter((p) => p.number);
        this.tooltipLabelOpenShow = "Скрыть все карты";
      } else {
        this.expandedRows = null;
        this.tooltipLabelOpenShow = "Раскрыть все карты";
      }
    },
    search(val) {
      if (val == "") {
        this.loadPage();
      }
    },
  },
  beforeUnmount() {
    this.updateCompanyInParent();
  },
  mounted() {
    this.loadPage();
    if (
      !this.filters.company.value.every(
        (value, index) => value === this.curCompanyList[index]
      ) &&
      history.state.back.split("/")[1] === "card"
    ) {
      this.updateCompanyInParent();
    } else {
      this.filters.company.value = this.curCompanyList;
    }
  },
};
</script>

<style lang="scss">
.overlock {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: 10001;
  }
}
.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right {
  color: #6c757d !important;
}

.card-wrapper {
  .p-datatable.table-cards {
    table {
      max-width: 100% !important;
      width: 100% !important;
    }
    .p-column-filter-menu {
      margin-left: 0rem;
    }
  }
  // .p-paginator .p-paginator-pages .p-paginator-page {
  //   min-width: 2rem;
  //   height: 2rem;
  // }
}
.cards-fulltable-pgn {
  bottom: 0px;
  padding-bottom: 5px !important;
  @media (max-width: 768px) {
    bottom: 65px;
  }
}
</style>