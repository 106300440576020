<template>
  <cards />
</template>

<script>
import Cards from "../modules/cards";
export default {
  components: { Cards },
  created() {
    document.title = "Карты | " + window.appBaseTitle;
  },
};
</script>

<style>
</style>