import api from "@/api/index";
import store from "@/store";
import {
    setEmoji
} from "@/handlers/emoji";
export function getReportCards(that) {
    if (store.state.company.curCompany == null) {
        return;
    }
    api
        .get("/report/get_cards?report", {
            responseType: "blob",
            params: {
                file_type: 'xlsx',
                company: that.filters.company_name.value !== null ?
                    that.filters.company_name.value.length !==
                    store.state.company.companyList.length ?
                    that.filters.company_name.value :
                    0 : null, 
                number: that.filters.number.value,
                status: that.filters.status.value,
                holder: that.filters.holder.value,
                search: that.search,
                group_id: that.filters.group.value != null && that.filters.group.value != 0 ?
                    that.filters.group.value[0] : null, 
                handler_code: that.filters.handler_code.value,
            },
        })
        .then((r) => {
            console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
            const href = URL.createObjectURL(r.data);
            const format = ".xlsx";
            const nameFile =
                "Отчет по картам " +
                format;
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", nameFile); //or any other extension
            document.body.appendChild(link);
            link.click();
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        })
        .catch((err) => {
            console.log(err);
            let statusCode = err.request.status;
            let statusText = err.request.statusText;
            console.error(setEmoji("error") + statusCode + " " + statusText);
            switch (statusCode) {
                case 0:
                    that.$toast.add({
                        severity: "warn",
                        summary: `${setEmoji("error")} Нет связи с сервером`,
                        detail: "Попробуйте позднее!",
                    });
                    break;
                case 403:
                    that.$toast.add({
                        severity: "warn",
                        summary: `${setEmoji("lock")} Время сессии истекло`,
                        detail: "Требуется авторизация",
                        life: 5000,
                    });
                    that.$router.push("/login");
                    break;
                case 404:
                    that.$toast.add({
                        severity: "error",
                        summary: `${setEmoji("error")} Ошибка ${statusCode}`,
                        detail: "Запрос не найден",
                        life: 5000,
                    });
                    break;
                default:
                    that.$toast.add({
                        severity: "error",
                        summary: `${setEmoji(
              "error"
            )} Ошибка получения списка карт  ${statusCode}`,
                        detail: "Попробуйте позднее!",
                        life: 5000,
                    });
                    break;
            }
        })
        .finally(() => {
            that.loading = false;
        });
}