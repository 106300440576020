import api from "@/api/index";
import store from "@/store";
import {
  setEmoji
} from "@/handlers/emoji";
import {
  getGroupCart
} from "./group";
import {
  getHandlersList
} from "./handlers";
export function getCards(that) {
  if (store.state.company.curCompany == null) {
    return;
  }
  api
    .get("/cards_v1/list", {
      params: {
        company: that.filters.company.value !== null ?
          that.filters.company.value.length !==
          store.state.company.companyList.length ?
          that.filters.company.value :
          0 : null,
        rows: that.rows,
        page: that.page,
        search: that.searchString,
        number: that.filters.number.value,
        status: that.filters.status.value,
        holder: that.filters.holder.value,
        search: that.search,
        group_id: that.filters.group.value != null && that.filters.group.value != 0 ?
          that.filters.group.value[0] : null,
        without_group: that.checkedWithoutGroup ?
          that.checkedWithoutGroup : null,
        handler_code: that.filters.handler_name.value,
      },
    })
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
      (that.isSearchLoad = false), (that.loadingCard = false);
      if (r.data.result.length === 0) {
        that.cards = null;
        return;
      }
      r.data.result.forEach((i) => {
        let checkStatus;
        switch (i.status.pk) {
          case 1:
            checkStatus = true;
            break;
          default:
            checkStatus = false;
            break;
        }
        i.checkStatus = checkStatus;
        checkStatus
          ?
          (i.checkMess = "Заблокировать") :
          (i.checkMess = "Разблокировать");
        i.disabled = false;
      });
      that.totalCount = r.data.total.count      ;
      that.cards = r.data.result;
      getGroupCart(that);
      getHandlersList(that);
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji(
              "error"
            )} Ошибка получения списка карт  ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.loading = false;
    });
}

export function getCardsTable(that) {
  if (store.state.company.curCompany == null) {
    return;
  }
  api
    .get("cards/table_list", {
      params: {
        company: that.filters.company_name.value !== null ?
          that.filters.company_name.value.length !==
          store.state.company.companyList.length ?
          that.filters.company_name.value :
          0 : null,
        rows: that.rows,
        page: that.page,
        number: that.filters.number.value,
        contract_num: that.filters.company_contract_num.value,
        holder: that.filters.holder.value,
        status: that.filters.status.value,
        handler_code: that.filters.handler_code.value,
        group_id: that.filters.group.value != null && that.filters.group.value != 0 ?
          that.filters.group.value[0] : null,
        without_group: that.checkedWithoutGroup ?
          that.checkedWithoutGroup : null,
        sort: that.sort,
        desc: that.desc,
      },
    })
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
      if (r.data.result.length === 0) {
        that.cards = null;
        return;
      } else {
        that.totalCount = r.data.total.count;
        that.cards = r.data.result;
        that.cards.push(r.data.total);
        getGroupCart(that);
        getHandlersList(that);
      }
      // getCardsTable(that);
      // return;
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji(
              "error"
            )} Ошибка получения списка карт  ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.loading = false;
    });
}