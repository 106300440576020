import api from "@/api/index";
import store from "@/store";
import { setEmoji } from "@/handlers/emoji";

export function getGroupCart(that) {
   let id = store.state.company.curCompany
    ? store.state.company.curCompany.id
    : null;

  if (id !== null) {
    api
      .get("groups/list", {
        params: {
          company: that.company,
        },
      })
      .then((r) => {
        console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
        that.list = r.data;
      })
      .catch((err) => {
        console.log(err);
        let statusCode = err.request.status;
        let statusText = err.request.statusText;
        console.error(setEmoji("error") + statusCode + " " + statusText);
        switch (statusCode) {
          case 0:
            that.$toast.add({
              severity: "warn",
              summary: `${setEmoji("error")} Нет связи с сервером`,
              detail: "Попробуйте позднее!",
            });
            break;
          case 403:
            that.$toast.add({
              severity: "warn",
              summary: `${setEmoji("lock")} Время сессии истекло`,
              detail: "Требуется авторизация",
              life: 5000,
            });
            that.$router.push("/login");
            break;
          case 404:
            that.$toast.add({
              severity: "error",
              summary: `${setEmoji("error")} Ошибка ${statusCode}`,
              detail: "Запрос не найден",
              life: 5000,
            });
            break;
          default:
            that.$toast.add({
              severity: "error",
              summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
              detail: "Попробуйте позднее!",
              life: 5000,
            });
            break;
        }
      })
      .finally(() => {
        that.loading = false;
      });
  }
}
export function bindingCardsforGroup(that, id) {
  api
    .post("cards", {
      id: id,
      action: "bind",
      card_numbers: that.cardNumber,
      company: that.company,
    })
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));

      that.$toast.add({
        severity: "success",
        detail: `${setEmoji("access")} Карта привязанна успешно`,
        life: 3000,
      });
      that.updateGroupInCard(that.selectedGroup);
      that.updateHistoryCard();
    })
    .catch((err) => {
      that.selectedGroup = that.selectGroup;
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.loading = false;
      that.visible = false;
    });
}
export function unbindingCardsforGroup(that) {
  api
    .post("cards", {
      action: "unbind",
      card_numbers: that.cardNumber,
    })
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
      that.$toast.add({
        severity: "success",
        detail: `${setEmoji("access")} Карта отвязана успешно`,
        life: 3000,
      });
      that.selectedGroup = null;
      that.updateGroupInCard(null);
      that.updateHistoryCard();
      that.visible = false;
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.loading = false;
    });
}

export function addGroupCart(that) {
  api
    .post("groups", {
      name:
        that.newGroupName && that.newGroupName != ""
          ? that.newGroupName
          : `Новая группа-${that.list.length}`,
      company: that.company,
      action: "add",
    })
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
      that.list.push(r.data);
      that.$toast.add({
        severity: "success",
        detail: `${setEmoji("access")} Группа карт ${
          r.data.name
        } добавлена успешно`,
        life: 3000,
      });
      that.newGroupName = null;
      if (that.addNow) {
        that.selectedGroup = r.data;
        that.onRowSelect(r);
      }
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.loading = false;
    });
}

export function delGroupCart(that, id) {
  api
    .post("/groups", {
      id: id,
      company: that.company,
      action: "del",
    })
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
       that.$toast.add({
        severity: "success",
        detail: `${setEmoji("trash")} Группа карт удалена успешно`,
        life: 3000,
      });
      let idx = that.list.findIndex((el) => el.id == id);
      that.list.splice(idx, 1);
      that.updateHistoryCard();
      if ((id = that.selectGroup.id)) {
        that.updateGroupInCard(null);
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.request.status === 0) {
        that.$toast.add({
          severity: "warn",
          summary: `Нет связи с сервером ${err.request.status}`,
          detail: "Попробуйте позднее!",
          life: 5000,
        });
      } else if (err.request.status == 403) {
        that.$toast.add({
          severity: "warn",
          summary: `Время сессии истекло`,
          detail: "Требуется авторизация",
          life: 5000,
        });
        that.$router.push("/login");
      } else {
        that.$toast.add({
          severity: "error",
          summary: `Неизвестная ошибка ${err.request.status}`,
          detail: "Попробуйте позднее!",
          life: 5000,
        });
      }
    })
    .finally(() => {
      that.loading = false;
    });
}

export function editNameGroupCart(that, newData, oldData, idx) {
  api
    .post("/groups", {
      id: oldData.id,
      name: newData.name,
      action: "edit",
    })
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
       that.list[idx] = r.data;
      that.$toast.add({
        severity: "success",
        detail: `${setEmoji("access")} Группа карт изменена успешно`,
        life: 3000,
      });
      if (r.data.id == oldData.id) {
        that.updateGroupInCard(newData);
      }
      that.updateHistoryCard();
    })
    .catch((err) => {
      that.list[idx] = oldData;
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.loading = false;
     });
}


// STARTS_WITH : 'startsWith',
// CONTAINS : 'contains',
// NOT_CONTAINS : 'notContains',
// ENDS_WITH : 'endsWith',
// EQUALS : 'equals',
// NOT_EQUALS : 'notEquals',
// IN : 'in', 
// LESS_THAN : 'lt',
// LESS_THAN_OR_EQUAL_TO : 'lte',
// GREATER_THAN : 'gt',
// GREATER_THAN_OR_EQUAL_TO : 'gte',
// BETWEEN : 'between',
// DATE_IS : 'dateIs',
// DATE_IS_NOT : 'dateIsNot',
// DATE_BEFORE : 'dateBefore',
// DATE_AFTER

