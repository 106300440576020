<template>
  <div
    class="card-wrapper h-screen overflow-hidden pb-0 bg-gray-200"
    v-if="curCompany !== null"
    :class="{ ' overlock': loading, 'h-auto': !loading }"
  >
    <!-- <div
      class="table-header-container mt-1 flex bg-gray-200 p-3 justify-content-end"
    >
      <div class="flex align-items-stretch">
        <Button
          v-tooltip="'Сбросить все фильтры'"
          @click="clearFilters"
          icon="pi pi-filter-slash"
        />
      </div>
    </div> -->
    <div
      class="table-header-container flex bg-gray-200 p-1 justify-content-end"
    >
      <div class="flex align-items-stretch">
        <!-- <Button
          v-tooltip.left="'Сбросить все фильтры'"
          @click="clearFilters"
          icon="pi pi-filter-slash"
          class="mr-2"
          style="width: 2rem; height: 2rem"
        /> -->
        <Button
          v-tooltip.left="'Выгрузить в XLS'"
          style="width: 6rem; height: 2rem"
          type="button"
          icon="pi pi-file-excel"
          label="XLSX"
          @click="getReport"
        />

        <Button
          class="ml-2"
          style="width: 2rem; height: 2rem"
          v-tooltip.left="'Обновить данные'"
          @click="loadPage"
          icon="pi pi-refresh"
        />
      </div>
    </div>
    <DataTable
      resizableColumns
      columnResizeMode="fit"
      showGridlines
      scrollable
      :page="page"
      :rows="rows + 1"
      :value="cards"
      :totalRecords="totalCount"
      class="p-datatable-sm h-full w-full full-table relative z-2"
      stripedRows
      lazy
      :loading="loading"
      dataKey="number"
      v-model:filters="filters"
      filterDisplay="menu"
      :globalFilterFields="['number']"
      ref="cardsTable"
      :rowClass="rowClass"
      selectionMode="single"
      @filter="applayFilters($event)"
      @sort="onSort($event)"
    >
      <template #empty>
        <div
          class="flex justify-content-center flex-column align-items-center my-6 w-full"
        >
          <i class="pi pi-inbox text-6xl mb-4 text-gray-700"> </i>
          <span class="text-gray-500">Нет данных</span>
        </div>
      </template>
      <Column field="row_number" style="width: 45px; max-width: 45px">
        <template #body="{ data }">
          {{ data.row_number ? data.row_number : data.all_cards }}
        </template></Column
      >
      <Column
        field="number"
        header="Номер карты"
        :showFilterMatchModes="false"
        :showClearButton="false"
        sortable
      >
        <template #body="slotProps">
          <div style="min-width: 90px !important; overflow: hidden">
            <img
              v-if="slotProps.data.number"
              style="margin-right: 1rem; width: 1.5rem"
              :src="
                getCardDesign(
                  slotProps.data.card_type.code,
                  slotProps.data.handler_code,
                  'cardIcon'
                )
              "
            />
            <span v-if="slotProps.data.number">
              {{ slotProps.data.number }}</span
            >

            <span v-else><b>Общий итог:</b></span>
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            :useGrouping="false"
            v-model.trim="filterModel.value"
            class="p-column-filter"
            placeholder="Введите номер карты"
            @keypress.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column field="date_of_issue" header="Дата выдачи">
        <template #body="slotProps">
          <div style="word-spacing: 0.5rem">
            {{ slotProps.data?.date_of_issue }}
          </div>
        </template></Column
      >
      <Column
        ref="companyColumn"
        field="company_name"
        header="Компания"
        sortable
        :showFilterMatchModes="false"
        :showApplyButton="false"
        :showClearButton="false"
        :filterMenuClass="'p-0-super'"
      >
        <template #filter="{ filterModel, filterCallback }">
          <MultiSelect
            display="chip"
            v-model="filterModel.value"
            :options="companyList"
            style="width: 60vw; max-width: 60vw"
            filter
            :showToggleAll="false"
            optionLabel="name"
            optionValue="id"
            placeholder="Выберите компанию"
            class="p-column-filter"
            ref="selecting"
            @change="selectingCompany($event)"
            emptyFilterMessage="Нет данных"
            emptyMessage="Нет данных"
          >
            <template #footer>
              <div class="flex justify-content-between gap-4 p-4 bg-gray-400">
                <ToggleButton
                  v-model="checkedAllCompany"
                  onLabel="Сбросить все"
                  offLabel="Выбрать все"
                  @click="this.$refs.selecting.onToggleAll()"
                />

                <Button label="Применить" @click="filterCallback(), hide()" />
              </div>
            </template>
          </MultiSelect> </template
      ></Column>

      <Column
        field="company_contract_num"
        header="Номер договора"
        sortable
        :showFilterMatchModes="false"
        :showClearButton="false"
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model.trim="filterModel.value"
            class="p-column-filter"
            placeholder="Введите номер договора"
            @keypress.enter="filterCallback()"
          /> </template
      ></Column>
      <Column
        field="holder"
        header="Держатель"
        sortable
        :showFilterMatchModes="false"
        :showClearButton="false"
      >
        <template #body="slotProps">
          {{
            slotProps.data.holder ? slotProps.data.holder.name : null
          }}</template
        >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model.trim="filterModel.value"
            class="p-column-filter"
            placeholder="Введите псевдоним"
            @keypress.enter="filterCallback()"
          />
        </template>
      </Column>
      <div v-if="$store.state.curUser && $store.state.curUser.role == 1">
        <Column
          field="handler_code"
          header="Обработчик"
          sortable
          :showFilterMatchModes="false"
          :showApplyButton="false"
          :showClearButton="false"
          :filterMenuClass="'p-0-super'"
        >
          <template #body="{ data }">
            {{ data.handler_name }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <MultiSelect
              display="chip"
              v-model="filterModel.value"
              :options="handlersList"
              style="width: 400px; max-width: 400px"
              filter
              :showToggleAll="false"
              optionLabel="full_name"
              optionValue="code"
              dataKey="id"
              placeholder="Выберите обработчика"
              class="p-column-filter"
              emptyFilterMessage="Нет данных"
              emptyMessage="Нет данных"
              ref="selectingHandlers"
              @change="selectingHandlers($event)"
            >
              <template #footer>
                <div class="flex justify-content-between gap-4 p-4 bg-gray-400">
                  <ToggleButton
                    v-model="checkedAllHandlers"
                    onLabel="Сбросить все"
                    offLabel="Выбрать все"
                    @click="this.$refs.selectingHandlers.onToggleAll()"
                  />
                  <Button label="Применить" @click="filterCallback(), show()" />
                </div>
              </template>
            </MultiSelect>
          </template>
        </Column>
      </div>
      <Column
        field="group"
        header="Группа карт"
        sortable
        :showFilterMatchModes="false"
        :showApplyButton="false"
        :showClearButton="false"
        :filterMenuClass="'p-0-super'"
      >
        <template #body="slotProps">
          {{
            slotProps.data.group ? slotProps.data.group.name : null
          }}</template
        >
        <template #filter="{ filterModel, filterCallback }">
          <MultiSelect
            :selectionLimit="1"
            display="chip"
            v-model="filterModel.value"
            :options="list"
            style="width: 400px; max-width: 400px"
            filter
            :showToggleAll="false"
            optionLabel="name"
            optionValue="id"
            placeholder="Выберите группу"
            class="p-column-filter"
            emptyFilterMessage="Нет данных"
            emptyMessage="Нет данных"
            @change="selectingGroup($event)"
            ref="selectingGroup"
          >
            <template #footer>
              <div class="flex justify-content-between gap-4 p-4 bg-gray-400">
                <div class="flex align-items-center">
                  <Checkbox
                    v-model="checkedWithoutGroup"
                    @input="clearSelectingGroup($event)"
                    inputId="addNow1"
                    binary
                    name="addNow"
                  />
                  <label for="addNow1" class="ml-2"> Без группы </label>
                </div>
                <Button label="Применить" @click="filterCallback(), show()" />
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="last_time_transaction" header="Дата последней транзакции">
        <template #body="slotProps">
          <div style="word-spacing: 0.5rem">
            {{ slotProps.data?.last_time_transaction }}
          </div>
        </template>
      </Column>
      <Column field="amount" header="Всего реализованно">
        <template #body="{ data }">
          {{ data.amount ? data.amount : data.litres_sum }}</template
        >
      </Column>
      <Column field="sum" header="На сумму">
        <template #body="{ data }">
          {{ data.sum ? data.sum : data.price_sum }}</template
        >
      </Column>
      <Column
        field="status"
        header="Статус"
        sortable
        :showFilterMatchModes="false"
        :showApplyButton="false"
        :showClearButton="false"
        :filterMenuClass="'p-0-super'"
      >
        <template #body="{ data }">
          <div v-if="data.status">
            {{ data.status.name }}
          </div>
          <div v-else class="text-center">
            <span
              v-tooltip.left="'Заблокировано'"
              class="text-red-500 cursor-pointer"
              >{{ data.blocked_cards }}</span
            >
            <span class="mx-2">/</span>
            <span
              v-tooltip.left="'Заблокировано вручную'"
              class="text-red-400 cursor-pointer"
              >{{ data.blocked_manually }}</span
            >
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <MultiSelect
            display="chip"
            v-model="filterModel.value"
            :options="statusItem"
            style="width: 400px; max-width: 400px"
            filter
            :showToggleAll="false"
            optionLabel="name"
            optionValue="value"
            placeholder="Выберите статус"
            class="p-column-filter"
            @change="selectingStatus($event)"
            ref="selectingStatus"
          >
            <template #footer>
              <div class="flex justify-content-between gap-4 p-4 bg-gray-400">
                <ToggleButton
                  v-model="checkedAllStatus"
                  onLabel="Сбросить все"
                  offLabel="Выбрать все"
                  @click="this.$refs.selectingStatus.onToggleAll()"
                />

                <Button label="Применить" @click="filterCallback(), show()" />
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
    </DataTable>
    <!-- <Paginator
      class="card-paginator bg-gray-200 p-2"
      @page="onPage($event)"
      :rows="rows"
      :totalRecords="totalCount"
      :rowsPerPageOptions="[10, 30, 50, 100, 500, 1000]"
    ></Paginator> -->
    <!-- <Paginator
      @page="onPage($event)"
      :totalRecords="totalCount"
      :page="page"
      :rows="rows"
      class="card-paginator bg-gray-200 p-2 z-1"
      :rowsPerPageOptions="[10, 30, 50, 100, 500, 1000]"
      template="FirstPageLink PrevPageLink  JumpToPageInput CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown "
      currentPageReportTemplate="из {totalPages}"
    /> -->
  </div>
  <the-paginator
    class="bg-gray-200 relative left-0 right-0 cards-fulltable-pgn pt-2"
    @page="onPage"
    :totalRecords="Boolean(totalCount) ? totalCount : 1"
    :rows="rows"
    :page="page"
    :globalDisabel="loading"
    :rowsPerPageOptions="[30, 50, 100, 300, 500, 1000]"
  />
</template>

<script>
import config from "@/configs";
import store from "@/store";
import { getCardsTable } from "../api/cards";
import { getReportCards } from "../api/report";
import { FilterMatchMode } from "primevue/api";
export default {
  name: "CardFullTable",
  props: {
    curCompanyList: {
      type: Array,
      requaried: true,
    },
  },
  data() {
    return {
      config: config,
      loading: true,
      cards: [],
      page: 1,
      rows: 100,
      sort: true,
      desc: true,
      totalCount: null,
      statusItem: [
        {
          value: 1,
          name: "Активна",
        },
        {
          value: 2,
          name: "Заблокирована",
        },
        {
          value: 3,
          name: "Заблокирована вручную",
        },
        {
          value: 4,
          name: "Закрыта",
        },
      ],
      filters: {
        number: { value: null, matchMode: FilterMatchMode.EQUALS },
        company_contract_num: {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        holder: {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        company_name: {
          value: this.curCompanyList,
          matchMode: FilterMatchMode.EQUALS,
        },
        status: {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        group: {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        handler_code: {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        },
      },
      checkedAllCompany: false,
      checkedAllStatus: false,
      checkedWithoutGroup: false,
      checkedAllHandlers: false,
      list: [],
      handlersList: [],
    };
  },
  methods: {
    hide() {
      this.$refs["selecting"].$parent.$parent.hide();
    },
    rowClass(data) {
      return data.all_cards
        ? "font-bold bg-gray-200 sticky bottom--1 hover:bg-gray-200"
        : "";
    },
    applayFilters(event) {
      this.loadPage();
    },
    loadPage() {
      this.loading = true;
      setTimeout(() => {
        getCardsTable(this);
      }, Math.random() * 1000 + 250);
    },
    onSort(event) {
      this.sort = event.sortField;
      event.sortOrder == 1 ? (this.desc = true) : (this.desc = null);
      this.loadPage();
    },
    // onPage(event) {
    //   if (this.page != event.page + 1 || this.rows != event.rows) {
    //     this.page = event.page + 1;
    //     this.rows = event.rows;
    //     this.loadPage();
    //   }
    // },
    onPage(data) {
      this.page = data.page;
      this.rows = data.rows;
      this.loadPage();
    },
    getCardDesign(code, subCode, setting) {
      let param = this.config.CARD_DISIGN["DEFAULT"][setting];
      if (code) {
        let disignSettings = this.config.CARD_DISIGN;
        let subDisignSettings = this.config.CARD_DISIGN.PPLUS;
        let arrayKey = [];
        let subArrayKey = [];
        for (let value of Object.keys(disignSettings)) {
          arrayKey.push(value);
        }
        for (let value of Object.keys(subDisignSettings)) {
          subArrayKey.push(value);
        }
        arrayKey.forEach((element) => {
          if (element === code) {
            if (code === "PPLUS") {
              subArrayKey.forEach((el) => {
                if (el == subCode) {
                  param = this.config.CARD_DISIGN[code][subCode][setting];
                }
                // else {
                //   param = this.config.CARD_DISIGN[code]["default"][setting];
                // }
              });
            } else {
              param = this.config.CARD_DISIGN[element][setting];
            }
          }
        });
      }
      return param;
    },
    overClick(data) {
      console.log((data.target.offsetParent.style.zIndex = "-1"));
      data.target.offsetParent.style.zIndex = "-1";
    },
    show() {
      console.log(this.$refs.selecting);
      // this.$refs.selecting.onToggleAll();
      // this.$refs.selecting.onCloseClick();
    },
    updateCompanyInParent() {
      this.$emit("update:curCompanyList", this.filters.company_name.value);
    },
    selectingCompany(e) {
      this.$emit("update:curCompanyList", e.value);
      if (e.value.length == this.companyList.length) {
        this.checkedAllCompany = true;
      } else {
        this.checkedAllCompany = false;
      }
    },
    selectingHandlers(e) {
      if (e.value.length == this.handlersList.length) {
        this.checkedAllHandlers = true;
      } else {
        this.checkedAllHandlers = false;
      }
    },
    selectingStatus(e) {
      if (e.value.length == this.statusItem.length) {
        this.checkedAllStatus = true;
      } else {
        this.checkedAllStatus = false;
      }
    },
    selectingGroup(e) {
      if (e.value.length > 0) {
        this.checkedWithoutGroup = false;
      }
    },
    clearSelectingGroup(e) {
      if (e && this.$refs.selectingGroup.modelValue != null) {
        this.filters.group.value = 0;
        this.$refs.selectingGroup.$emit("update:modelValue");
      }
      if (e) {
        this.filters.group.value = 0;
      } else {
        this.filters.group.value = null;
      }
    },
    clearFilters() {
      console.log(Object.keys(this.filters));
      Object.keys(this.filters).forEach((el) => {
        this.filters[el].value = null;
      });
      this.checkedWithoutGroup = false;
      this.loadPage();
    },
    getReport() {
      this.loading = true;
      getReportCards(this);
    },
  },
  computed: {
    companyList() {
      return store.state.company.companyList.map(function (el) {
        return {
          id: el.id,
          name: el.full_name,
        };
      });
    },
  },
  watch: {},
  beforeUnmount() {
    this.updateCompanyInParent();
  },
  mounted() {
    this.loadPage();
    // console.log(this.$refs.cardsTable.$el.offsetParent);
  },
};
</script>

<style lang="scss">
// .p-datatable.p-datatable-sm .p-datatable-tbody tr {
//   th,
//   td {
//     width: fit-content !important;
//     max-width: fit-content !important;
//   }
// }
.p-resizable-column {
  white-space: nowrap !important;
  .p-column-header-content {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-wrapper {
  .p-datatable.full-table {
    .bottom--1 {
      bottom: -1px;
    }
    position: relative;
    table {
      max-width: 100% !important;
      width: 100% !important;
    }
    .p-datatable-emptymessage {
      tr {
        td {
          flex: 0 0 100% !important;
        }
      }
    }
    .p-datatable-wrapper {
      // height: calc(100vh - 250px) !important;
      // height: calc(100vh - 175px) !important;
      // height: calc(100vh - 135px) !important;
      height: calc(100vh - 155px) !important;
      @media (min-width: 1440px) {
        height: calc(100vh - 175px) !important;
      }
    }

    &.p-datatable-hoverable-rows
      .p-datatable-tbody
      > tr:not(.p-highlight):not(
        .p-selectable-row.font-bold.bg-gray-200
      ):hover {
      outline: none !important;
      background-color: rgba(90, 146, 249, 0.368627451) !important;
    }
    & .p-datatable-tbody > tr:focus {
      outline: none !important;
    }
    &.p-datatable-sm .p-datatable-thead > tr > th {
      padding: 0.1rem 0.5rem;
    }
    &.p-datatable-sm .p-datatable-tbody > tr > td {
      padding: 0.25rem 0.5rem;
    }
  }
  .p-datatable.p-datatable-hoverable-rows
    .p-datatable-tbody
    > tr:not(.p-highlight):hover {
    background: #eee !important;
  }
}

.p-column-resizer {
  right: -0.5rem !important;
  width: 1rem !important;
  z-index: 999;
  &:hover {
    background: var(--gray-400) !important;
  }
}
.p-column-filter-menu-button.p-column-filter-menu-button-active {
  overflow: visible;
  background: transparent !important;
  color: #6c757d !important;
  .pi.pi-filter-icon.pi-filter::after {
    content: "";
    position: absolute;
    margin-top: -6px;
    margin-right: -6px;
    width: 8px;
    height: 8px;
    background: var(--base);
    border-radius: 50%;
  }
}
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover,
.p-column-filter-menu-button.p-column-filter-menu-button-open,
.p-datatable .p-sortable-column.p-highlight {
  background: transparent !important;
  color: #6c757d !important;
}

.p-column-filter-buttonbar {
  gap: 1.5rem !important;
}

.p-0-super {
  .p-column-filter-buttonbar {
    padding: 0 !important;
  }
}
.p-datatable-resizable {
  .p-datatable-wrapper {
    overflow-x: unset !important;
  }
}
.p-datatable-scrollable {
  &.p-datatable-wrapper {
    overflow-x: unset !important;
  }
}
.cards-fulltable-pgn {
  bottom: 0;
  @media (max-width: 768px) {
    bottom: 65px;
  }
}
</style>