<template>
  <Button
    :label="selectHolder ? selectHolder.name : 'Держатель'"
    class="p-button-sm"
    :class="
      selectHolder == null
        ? 'text-center p-2'
        : 'p-button-text text-left underline w-full px-0'
    "
    @click="getHolderList"
    style="
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 2rem;
    "
  ></Button>
  <Dialog
    v-model:visible="visible"
    :modal="true"
    maximizable="true"
    :draggable="false"
    header="Список держателей"
    :style="{ width: '90vw' }"
    contentClass="h-screen"
    style="min-height: 50vh"
    :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
  >
    <template #header>
      <ConfirmPopup ref="conf" class="w-20rem" group="demo">
        <template #message="slotProps">
          <div class="flex p-4 flex-column w-full">
            <div class="flex mb-4 align-items-center">
              <i :class="slotProps.message.icon" class="text-xl"></i>
              <p class="pl-2">{{ slotProps.message.message }}</p>
            </div>
            <InputText
              ref="inputNameHolder"
              autofocus
              class="p-2"
              @keydown.enter="enterAddHolder"
              @keydown.esc="closeAddHolder"
              v-model.trim="newHolderName"
            />

            <div class="flex align-items-center mt-4">
              <Checkbox
                v-model.trim="addNow"
                inputId="addNow1"
                binary
                name="addNow"
              />
              <label for="addNow1" class="ml-2"> Привязать к карте </label>
            </div>
          </div>
        </template>
      </ConfirmPopup>
      <div>
        <Button
          class="mb-4 mr-2"
          icon="pi"
          label="Создать держателя"
          @click="addHolders($event)"
          :loading="loading"
        />
        <Button
          class="mb-4"
          icon="pi"
          :disabled="!selectHolder"
          label="Отвязать держателя"
          @click="unbindHolders"
          :loading="loading"
        />
      </div>
    </template>
    <DataTable
      :value="list"
      :loading="loading"
      class="p-datatable-sm"
      dataKey="pk"
      editMode="row"
      @rowExpand="onRowExpand"
      @row-edit-save="onRowEditSave"
      @rowSelect="onRowSelect"
      v-model:editingRows="editingRows"
      v-model:selection="selectedHolder"
      v-model:expandedRows="expandedRows"
      ref="holderTables"
    >
      <template #empty>
        <div
          class="flex justify-content-center flex-column align-items-center my-6"
        >
          <i class="pi pi-inbox text-6xl mb-4 text-gray-700"> </i>
          <span class="text-gray-500">Нет данных</span>
        </div>
      </template>
      <Column selectionMode="single" headerStyle="width: 3rem"></Column>
      <Column expander style="width: 5rem" bodyStyle="text-align:center" />

      <Column field="name" header="Псевдоним" style="max-width: 300px">
        <template #editor="{ data, field }">
          <InputText
            class="p-2 w-full"
            v-model="data[field]"
            @keydown.enter="enter(data[field])"
            @keydown.esc="cancel(data[field])"
          /> </template
      ></Column>
      <Column field="cards" header="Кол-во карт" bodyStyle="text-align:center">
        <template #body="slotProps">
          {{ slotProps.data.cards.length }}</template
        ></Column
      >
      <Column field="first_name" header="Имя">
        <template #editor="{ data, field }">
          <InputText
            class="p-2 w-full"
            v-model="data[field]"
            @keydown.enter="enter(data[field])"
            @keydown.esc="cancel(data[field])"
          /> </template
      ></Column>
      <Column field="middle_name" header="Отчество">
        <template #editor="{ data, field }">
          <InputText
            class="p-2 w-full"
            v-model="data[field]"
            @keydown.enter="enter(data[field])"
            @keydown.esc="cancel(data[field])"
          /> </template
      ></Column>
      <Column field="last_name" header="Фамилия">
        <template #editor="{ data, field }">
          <InputText
            class="p-2 w-full"
            v-model="data[field]"
            @keydown.enter="enter(data[field])"
            @keydown.esc="cancel(data[field])"
          /> </template
      ></Column>
      <Column
        :rowEditor="true"
        style="width: 8rem"
        bodyStyle="text-align:center"
      ></Column>
      <Column bodyStyle="text-align:center">
        <template #body="slotProps">
          <ConfirmPopup />
          <Button
            :loading="false"
            icon="pi pi-trash"
            class="p-button-danger p-button-text p-button-rounded"
            severity="danger"
            text
            rounded
            aria-label="Cancel"
            @click="delLimit($event, slotProps.data.pk)"
          /> </template
      ></Column>

      <template #expansion="slotProps">
        <div class="p-2 bg-gray-500">
          <!-- <h4>Карты привязаные к держателю {{ slotProps.data.name }}</h4> -->
          <DataTable :value="slotProps.data.cards" class="p-datatable-sm mt-0">
            <template #empty>
              <div
                class="flex justify-content-center flex-column align-items-center my-3"
              >
                <i class="pi pi-inbox text-4xl mb-2 text-gray-700"> </i>
                <span class="text-gray-500 text-lg">Нет данных</span>
              </div>
            </template>
            <Column field="number" header="Номер карты"></Column>
            <Column field="card_type_name" header="Бренд"></Column>
          </DataTable>
        </div>
      </template>
    </DataTable>
  </Dialog>
</template>

<script>
import {
  getHolderList,
  delHolder,
  addHolder,
  setHolder,
  editHolder,
  unbindHolder,
} from "../api/holders";
export default {
  name: "CardHolders",
  props: {
    company: {
      type: Number,
      required: true,
    },
    selectHolder: {
      type: Object,
      required: true,
      default: null,
    },
    cardNumber: {
      type: Number,
      requaried: true,
    },
  },
  data() {
    return {
      visible: false,
      loading: !this.visible,
      list: [],
      expandedRows: [],
      editingRows: [],
      selectedHolder: this.selectHolder,
      addNow: true,
      newHolderName: null,
    };
  },
  methods: {
    getHolderList() {
      this.visible = true;
      getHolderList(this);
    },
    updateHistoryCard() {
      this.$emit("updateHistory", {});
    },
    onRowExpand(event) {
      //   this.$toast.add({
      //     severity: "info",
      //     summary: "Product Expanded",
      //     detail: event.data.name,
      //     life: 3000,
      //   });
    },
    delLimit(event, id) {
      console.log(event, id);
      // delLimit(this, id);
      this.$confirm.require({
        target: event.currentTarget,
        message: "Вы действительно хотите удалить держателя?",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          delHolder(this, id);
          this.loading = true;
        },
      });
    },
    addHolders(event) {
      setTimeout(() => {
        this.$refs.inputNameHolder.$el.focus();
      }, 50);
      this.$confirm.require({
        target: event.currentTarget,
        group: "demo",
        message: "Введите псевдоним",
        icon: "pi pi-user-plus",
        acceptIcon: "pi pi-check",
        rejectIcon: "pi pi-times",
        accept: () => {
          this.loading = true;
          addHolder(this);
        },
        reject: () => {
          console.log(this.$refs.inputNameHolder);
          this.newHolderName = null;
        },
      });
    },
    unbindHolders() {
      unbindHolder(this);
    },
    enter(event) {
      this.$refs.holderTables.onRowEditInit(event);
    },
    enterAddHolder() {
      this.$refs.conf.accept();
    },
    closeAddHolder() {
      this.$refs.conf.reject();
    },
    cancel(event) {
      this.$refs.holderTables.onRowEditCancel(event);
    },
    onRowEditSave(event) {
      let { newData, index, data } = event;
      //   this.list[index] = newData;
      if (JSON.stringify(newData) != JSON.stringify(data)) {
        this.loading = true;
        editHolder(this, index, data, newData);
      } else {
        return;
      }
    },
    onRowSelect(event) {
      setHolder(this, event.data.pk);
    },
    updateHolderInCard(val) {
      this.$emit("update:selectHolder", val);
      if (val == null) {
      }
    },
  },
};
</script>

<style>
.p-confirm-popup.p-component,
.p-confirm-popup.p-component.p-confirm-popup-flipped {
  z-index: 10005 !important;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  outline: none !important;
  background-color: rgba(90, 146, 249, 0.368627451) !important;
  color: #495057;
}
.p-dialog.p-dialog-maximized {
  max-height: 100vh;
}
</style>